.app {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 70px;
}

.job-categories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  align-items: center;
  padding-right: 90px;
}

.job-categories .job-category:nth-child(1) {
  grid-column: 1 / span 2;
  margin: 10px;
}

.job-categories .job-category:nth-child(2) {
  grid-row: 2;
  grid-column: 1;
  margin: 10px;
}

.job-categories .job-category:nth-child(3) {
  grid-row: 2;
  grid-column: 2;
  margin: 10px;
}

.job-categories .job-category:nth-child(4) {
  grid-column: 1 / span 2;
  grid-row: 3;
  margin: 10px;
}

.next-steps-section {
  cursor: pointer;
}


@media (max-width: 900px) {
  .app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    margin-bottom: 70px;
  }

  .job-categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin-bottom: 90px;
    padding-right: 0;
  }

  .job-categories .job-category {
    grid-column: 1 / span 1;
    margin: 10px;
  }
}



@media (max-width: 750px) {
  .app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 60px;
  }

  .job-categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin-bottom: 90px;
    padding-right: 0;
  }

  .job-categories .job-category {
    grid-column: 1 / span 1;
    margin: 10px;
  }
}



@media (max-width: 400px) {
  .app {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 50px; 
  }

  .job-categories {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto;
    gap: 2px; 
    justify-items: center;
    align-items: center;
    margin-left: 60px;
    padding-right: 0; 
  }

  .job-categories .job-category {
    grid-column: 1 / span 1; 
    margin: 5px; 
  }
}
