// Modal overlay styles
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-spinner-container {
  text-align: center;
  color: #ffffff;
}

.modal-text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}