* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}


body {
  font-family: 'Open-Sans',sans-serif;
  padding: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
