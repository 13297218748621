.landing-page {
  width: 100%;
}

button {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button {
  background-color: #063314;
  color: white;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.signup-button {
  background-color: #063314;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

button:hover {
  opacity: 0.8;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.logo img {
  width: 150px;
  height: 50px;
  object-fit: fill;
}

.page-content {
  margin-top: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px;
  .header {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 57px;
      color: #063314;
    }

    h3 {
      margin-top: 50px;
      color: #13411d;
      font-size: 32px;
    }

    p {
      margin-top: 50px;
      color: #006633;
      width: 75%;
      font-size: 18px;
    }
  }

  .landingImage img {
    width: 550px;
    height: 400px;
    margin-top: -50px;
  }
}

.try-button {
  background-color: #063314 ;
  font-weight: bold;
  font-size: 18px;
  color: white;
  width: 20%;
  margin-top: 45px;
}

.tooltip {
  position: relative;
  background: transparent;
  color: #000000;
  padding: 5px;
  font-size: 12px;
  font-weight: 200;
  left: 27.5%;
}

@media (max-width: 1300px) {
  .page-content {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0 40px;
  }

  .landingImage {
    order: -1; /* Move landing image to the top */
    margin-bottom: 25px;
    text-align: center;
  }

  .landingImage img {
    margin-top: -10px;
    width: 85%;
    max-width: 500px;
    height: auto;
    object-fit: contain;
  }
}

@media (max-width: 950px) {
  .page-content {
    flex-direction: column-reverse; /* For smaller widths, reverse the order */
    margin-top: 30px;
    padding: 0 30px;
  }

  .landingImage {
    order: 1; /* Revert the landing image to below */
    margin-bottom: 20px;
  }

  .landingImage img {
    width: 90%;
    max-width: 400px;
    height: 180px;
    object-fit: contain;
  }
}





@media (max-width: 950px) {
  .logo img {
    width: 170px;
    height: 90px;
    object-fit: contain;
    margin-left: 40px;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 30px;
  }

  .page-content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 0 30px;
  }

  .landingImage {
    margin-bottom: 20px;
    text-align: center;
  }

  .landingImage img {
    margin-top: -15px;
    width: 90%;
    max-width: 400px;
    height: 180px;
    object-fit: contain;
  }

  .header {
    text-align: center;
    margin-top: -40px;
  }

  .header h2 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .header h3 {
    font-size: 20px;
    margin-top: 10px;
  }

  .header p {
    font-size: 15px;
    width: 85%;
    margin-top: 12px;
    margin-bottom: 22px;
    margin-left: 70px;
    line-height: 1.7;
  }

  .try-button {
    width: 45%;
    padding: 14px;
    margin-top: 20px;
    margin-left: 150px;
    font-size: 16px;
  }
}






@media (max-width: 750px) {
  .logo img {
    width: 150px;
    height: 80px;
    object-fit: contain;
    margin-left: 30px;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .page-content {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
  }

  .landingImage {
    margin-bottom: 20px;
    text-align: center;
  }

  .landingImage img {
    margin-top: -20px;
    width: 85%;
    max-width: 300px;
    height: 160px;
    object-fit: contain;
  }

  .header {
    text-align: center;
    margin-top: -50px;
  }

  .header h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .header h3 {
    font-size: 18px;
    margin-top: 10px;
  }

  .header p {
    font-size: 14px;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 60px;
    line-height: 1.6;
  }

  .try-button {
    width: 50%;
    padding: 12px;
    margin-top: 15px;
    margin-left: 100px;
    font-size: 16px;
  }
}


@media (max-width: 400px) {
  .logo img {
    width: 120px;
    height: 60px;
    object-fit: contain;
    margin-left: 20px;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0;
  }

  .page-content {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10px;
    padding: 0 5px;
  }

  .landingImage {
    margin-bottom: 15px;
    text-align: center;
  }

  .landingImage img {
    margin-top: -40px;
    width: 80%;
    max-width: 250px;
    height: 120px;
    object-fit: contain;
  }

  .header {
    text-align: center;
    margin-top: -110px;
  }

  .header h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .header h3 {
    font-size: 14px;
    margin-top: 5px;
  }

  .header p {
    font-size: 12px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 30px;
    line-height: 1.4;
  }

  .try-button {
    width: 90%;
    padding: 8px;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 14px;
  }
}
