
.all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: 80vh;
  margin-top: 50px;
  gap: 30px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgb(148, 187, 148);
}

.forward {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top p {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.ql-container {
  border: none !important;
}

.ql-editor {
  border: none !important;
}

.box {
    line-height: 1.2;
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: sans-serif;
    white-space: wrap;
    border: 2px dashed rgb(202, 242, 202);
    background-color: rgb(246, 248, 246);
    width: 460px;
    height: 427px;
    text-align: left;
    vertical-align: top;
    transition: background-color 0.3s, border-color 0.3s;
}

.box:hover {
  background-color: rgb(250, 253, 250);
  border-color: rgb(202, 242, 202);
}


.box:focus {
  background-color: #fff;
  border-color: rgb(202, 242, 202);
  outline: none;
}

.tton button {
  background-color: #063314;
  color: white;
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  outline: none;
  transition: background-color 0.4s;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
}

button :hover {
  background-color: #063314;
}

#signup {
  width: 12px;
  height: 12px;
  appearance: none;
  -webkit-appearance: none; 
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: -1px;
}

#signup:checked {
  background-color: #13411d;
  border-color: #13411d;
}

.checkbox-label {
  font-size: 12px;
  color: #006633;
}

.select-label {
  font-size: 15px;
  color: #063314;
}

.file-select {
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.file-select:focus {
  background-color: #fff;
  border-color: rgb(202, 242, 202);
  outline: none;
}

.star {
  width: 6px;
  height: 6px;
}



@media (max-width: 900px) {
  .all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%; 
    height: auto; 
    margin-top: 80px; 
    gap: 30px; 
    padding: 25px; 
    box-shadow: 0px 4px 8px rgb(148, 187, 148);
  }

  .forward {
    display: flex;
    flex-direction: column;
    gap: 15px; 
  }

  .top p {
    font-weight: bold;
    font-size: 20px; 
    text-align: center;
  }

  .box {
    line-height: 1.2;
    resize: none;
    overflow-y: auto; 
    overflow-x: hidden;
    font-family: sans-serif;
    white-space: wrap;
    border: 2px dashed rgb(202, 242, 202);
    background-color: rgb(246, 248, 246);
    width: 100%; 
    height: 400px;
    text-align: left;
    vertical-align: top;
    transition: background-color 0.3s, border-color 0.3s;
    padding: 15px; 
  }

  .box:hover {
    background-color: rgb(250, 253, 250);
    border-color: rgb(202, 242, 202);
  }

  .box:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  #signup {
    width: 16px; 
    height: 16px; 
    appearance: none;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: -1px;
  }

  #signup:checked {
    background-color: #13411d;
    border-color: #13411d;
  }

  .checkbox-label {
    font-size: 16px; 
    color: #006633;
  }

  .select-label {
    font-size: 18px; 
    color: #063314;
  }

  .file-select {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  .file-select:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  .star {
    width: 10px; 
    height: 10px; 
  }
}


@media (max-width: 750px) {
  .all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%; 
    height: auto; 
    margin-top: 100px; 
    gap: 25px;
    padding: 20px; 
    box-shadow: 0px 4px 8px rgb(148, 187, 148);
  }

  .forward {
    display: flex;
    flex-direction: column;
    gap: 10px; 
  }

  .top p {
    font-weight: bold;
    font-size: 18px; 
    text-align: center;
  }

  .box {
    line-height: 1.2;
    resize: none;
    overflow-y: auto; 
    overflow-x: hidden;
    font-family: sans-serif;
    white-space: wrap;
    border: 2px dashed rgb(202, 242, 202);
    background-color: rgb(246, 248, 246);
    width: 100%; 
    height: 350px; 
    text-align: left;
    vertical-align: top;
    transition: background-color 0.3s, border-color 0.3s;
    padding: 12px; 
  }

  .box:hover {
    background-color: rgb(250, 253, 250);
    border-color: rgb(202, 242, 202);
  }

  .box:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  #signup {
    width: 14px;
    height: 14px;
    appearance: none;
    -webkit-appearance: none; 
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: -1px;
  }

  #signup:checked {
    background-color: #13411d;
    border-color: #13411d;
  }

  .checkbox-label {
    font-size: 14px; 
    color: #006633;
  }

  .select-label {
    font-size: 16px;
    color: #063314;
  }

  .file-select {
    width: 100%;
    margin-top: 8px;
    padding: 8px;
    font-size: 15px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  .file-select:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  .star {
    width: 8px; 
    height: 8px; 
  }
}



@media (max-width: 400px) {
  .all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%; 
    height: auto;
    margin-top: 200px; 
    gap: 20px; 
    padding: 15px; 
    box-shadow: 0px 4px 8px rgb(148, 187, 148);
  }

  .forward {
    display: flex;
    flex-direction: column;
    gap: 8px; 
  }

  .top p {
    font-weight: bold;
    font-size: 16px; 
    text-align: center;
  }

  .box {
    line-height: 1.2;
    resize: none;
    overflow-y: auto; 
    overflow-x: hidden;
    font-family: sans-serif;
    white-space: wrap;
    border: 2px dashed rgb(202, 242, 202);
    background-color: rgb(246, 248, 246);
    width: 100%; 
    height: 300px; 
    text-align: left;
    vertical-align: top;
    transition: background-color 0.3s, border-color 0.3s;
    padding: 10px;
  }

  .box:hover {
    background-color: rgb(250, 253, 250);
    border-color: rgb(202, 242, 202);
  }

  .box:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  #signup {
    width: 12px;
    height: 12px;
    appearance: none;
    -webkit-appearance: none; 
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: -1px;
  }

  #signup:checked {
    background-color: #13411d;
    border-color: #13411d;
  }

  .checkbox-label {
    font-size: 12px; 
    color: #006633;
  }

  .select-label {
    font-size: 14px; 
    color: #063314;
  }

  .file-select {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    font-size: 14px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  .file-select:focus {
    background-color: #fff;
    border-color: rgb(202, 242, 202);
    outline: none;
  }

  .star {
    width: 6px;
    height: 6px;
  }
}



