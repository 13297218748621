.job-category {
  position: relative;
  text-align: center;
  width: 200px;
  height: 200px
}

.job-category-match {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
}

.job-category-label {
  margin-top: 16px;
  color: #1b4d3e;
  font-weight: bold;
}