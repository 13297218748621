* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open-sans", sans-serif;
  line-height: 1.6;
}

.design {
  border: 1px dashed rgb(202, 242, 202);
  background-color:  rgb(246, 248, 246);
  padding:5px 40px ;
}

.header {
  text-align: left;
}

.policybody {
  padding-top: 2em;
  max-width: 800px;
  margin: "0 auto";
}

.list {
  margin-left: 2em;
  li {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}

.right 
{
  text-align: center;
  padding: 10px;
  width: 100%;
  bottom: 0;
  border-top: 1px solid green;
}

.match 
{
  text-align: center;
}

h2 {
  margin-top: 0.5em;
}

.paragraph {
  margin-top: 0.25em;
}
