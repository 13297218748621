.next-steps {
  background-color: #0d3b2d;
  color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 250px;
  height: 250px;
  text-align: center;
}

.next-steps h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 50px;
}

.next-steps::before {
  content: '';
  background-color: #dc4c2c;
  position: absolute;
  top: -30px;
  right: -30px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 8px;
}

