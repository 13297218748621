.main {
    font-weight: bold;
    text-align: center;
}

.info {
    font-weight: lighter;
    font-size: small;
    text-align: center;
    color: #666;
}

.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 80vh;
    gap: 30px;
    margin-top: 50px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgb(148, 187, 148);
}

.main2 p {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.main3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    border: 2px dashed rgb(202, 242, 202);
    background-color: rgb(246, 248, 246);
    width: 460px;
    height: 427px;
    padding-top: 100px;
}

.main7 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Logo-Image {
    width: 70px;
    height: 60px;
    margin-bottom: 15px;
}

.Uploaded-Image {
    width: 70px;
    height: 70px;
    margin-bottom: 5px;
}

.browse {
    cursor: pointer;
    color: #063314;
    text-decoration: underline;
}

.main4 button {
    background-color: #063314;
    color: white;
    font-size: 16px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
}

.main4 button:disabled {
    color: #666;
    cursor: not-allowed;
    opacity: 0.7;
}

button :hover {
    background-color: #063314;
}



@media (max-width: 900px) {
    .main {
      font-weight: bold;
      text-align: center;
      font-size: 20px; /* Slightly larger font size for readability */
    }

    .info {
      font-weight: lighter;
      font-size: small; /* Consistent font size for info section */
      color: #666;
    }

    .main1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 75%; /* Adjusted width for better use of space */
      height: auto;
      gap: 35px; /* Slightly larger gap between elements */
      margin-top: 80px; /* Adjusted margin-top for better spacing */
      padding: 25px; /* Increased padding for better spacing */
      box-shadow: 0px 4px 8px rgb(148, 187, 148);
    }

    .main2 p {
      font-weight: bold;
      font-size: 20px; 
      text-align: center;
    }

    .main3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      border: 2px dashed rgb(202, 242, 202);
      background-color: rgb(246, 248, 246);
      width: 90%; 
      height: auto;
      padding-bottom: 40px;
      padding-top: 90px; 
    }

    .main7 {
      display: flex;
      flex-direction: column;
      gap: 15px; 
    }

    .Logo-Image {
      width: 80px; 
      height: 70px; 
      margin-bottom: 15px; 
    }

    .Uploaded-Image {
      width: 80px; 
      height: 80px; 
      margin-bottom: 10px;
    }

    .browse {
      cursor: pointer;
      color: #063314;
      text-decoration: underline;
      font-size: 18px; 
    }

    .main4 button {
      background-color: #063314;
      color: white;
      font-size: 18px; 
      width: 100%;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      outline: none;
      transition: background-color 0.4s;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
      padding: 16px; 
    }

    .main4 button:disabled {
      color: #666;
      cursor: not-allowed;
      opacity: 0.7;
    }

    button:hover {
      background-color: #063314;
    }
}



@media (max-width: 750px) {
    .main {
      font-weight: bold;
      text-align: center;
      font-size: 18px; 
    }

    .info {
      font-weight: lighter;
      font-size: small; 
      color: #666;
    }

    .main1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%; 
      height: auto;
      gap: 30px; 
      margin-top: 100px; 
      padding: 20px; 
      box-shadow: 0px 4px 8px rgb(148, 187, 148);
    }

    .main2 p {
      font-weight: bold;
      font-size: 18px; 
      text-align: center;
    }

    .main3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px; 
      border: 2px dashed rgb(202, 242, 202);
      background-color: rgb(246, 248, 246);
      width: 90%; 
      height: auto;
      padding-bottom: 40px;
      padding-top: 80px; 
    }

    .main7 {
      display: flex;
      flex-direction: column;
      gap: 12px; 
    }

    .Logo-Image {
      width: 70px; 
      height: 60px; 
      margin-bottom: 12px; 
    }

    .Uploaded-Image {
      width: 70px;
      height: 70px; 
      margin-bottom: 8px;
    }

    .browse {
      cursor: pointer;
      color: #063314;
      text-decoration: underline;
      font-size: 16px; 
    }

    .main4 button {
      background-color: #063314;
      color: white;
      font-size: 16px; 
      width: 100%;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      outline: none;
      transition: background-color 0.4s;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
      padding: 14px; 
    }

    .main4 button:disabled {
      color: #666;
      cursor: not-allowed;
      opacity: 0.7;
    }

    button:hover {
      background-color: #063314;
    }
}



@media (max-width: 400px) {
    .main {
      font-weight: bold;
      text-align: center;
      font-size: 16px; 
    }
  
    .info {
      font-weight: lighter;
      font-size: x-small; 
      color: #666;
    }
  
    .main1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%; 
      height: auto; 
      gap: 20px; 
      margin-top: 200px; 
      padding: 15px; 
      box-shadow: 0px 4px 8px rgb(148, 187, 148);
    }
  
    .main2 p {
      font-weight: bold;
      font-size: 16px; 
      text-align: center;
    }
  
    .main3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px; 
      border: 2px dashed rgb(202, 242, 202);
      background-color: rgb(246, 248, 246);
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 90%; 
      height: auto; 
      padding-top: 60px; 
    }
  
    .main7 {
      display: flex;
      flex-direction: column;
      gap: 8px; 
    }
  
    .Logo-Image {
      width: 60px; 
      height: 50px; 
      margin-bottom: 10px; 
    }
  
    .Uploaded-Image {
      width: 60px;
      height: 60px; 
      margin-bottom: 5px;
    }
  
    .browse {
      cursor: pointer;
      color: #063314;
      text-decoration: underline;
      font-size: 14px; 
    }
  
    .main4 button {
      background-color: #063314;
      color: white;
      font-size: 14px;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      outline: none;
      transition: background-color 0.4s;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
      padding: 12px; 
    }
  
    .main4 button:disabled {
      color: #666;
      cursor: not-allowed;
      opacity: 0.7;
    }
  
    button:hover {
      background-color: #063314;
    }
  }
  