.available-roles {
  width: 100%;
}

h3 {
  margin-bottom: 10px;
  color: #2e4924;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 10px;
}

th {
  font-weight: bold;
  color: #2e4924;
  border-bottom: 1px solid #ccc;
}

.spinner-container {
  text-align: center;
  color: #0d3b2d;
  align-items: center;
}

.spinner-text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
}

.role-row {
  color: #333;
}

.role-name {
  display: flex;
  align-items: center;
}

.role-name a {
  color: #2e4924;
  text-decoration: none;
  font-weight: bold;
}

.role-name a:hover {
  text-decoration: underline;
}

.bullet {
  color: #F55D0B;
  font-size: xx-large;
  font-weight: bolder;
  margin-right: 15px;
}

.role-compatibility {
  color: #2e4924;
  font-weight: bold;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}